import Image from "next/legacy/image"

const ImageComponent = ({ children, src, width, height, alt, unoptimized, ...rest }) => {
  const widthProps = width || 10
  const heightProps = height || 10
  const srcProps = src || ""
  return (
    <>
      <Image
        // unoptimized={true}
        alt={"Image"}
        width={widthProps}
        height={heightProps}
        src={srcProps}
        loading={"eager"}
        layout="fixed"
      >
        {children}
      </Image>
    </>
  )
}

export default ImageComponent
